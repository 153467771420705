var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "color-picker-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "picker-control",
        on: {
          click: function($event) {
            _vm.showBaseColorPicker = !_vm.showBaseColorPicker
          }
        }
      },
      [
        _c("div", {
          staticClass: "color-display mr-2",
          style: `background: ${_vm.backgroundColorPicker}`
        }),
        _c("b-icon", {
          staticStyle: {
            width: "20px",
            height: "20px",
            float: "left",
            "margin-top": "5px"
          },
          attrs: { icon: "droplet-fill" }
        })
      ],
      1
    ),
    _vm.showBaseColorPicker
      ? _c(
          "div",
          { staticClass: "color-picker" },
          [
            _c("chrome-picker", {
              model: {
                value: _vm.tempColor,
                callback: function($$v) {
                  _vm.tempColor = $$v
                },
                expression: "tempColor"
              }
            }),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mb-3",
                    attrs: { variant: "light" },
                    on: { click: _vm.cancelColor }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("webmag.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-3 mb-3",
                    attrs: { variant: "success" },
                    on: { click: _vm.saveColor }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("webmag.select")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }