<template>
  <div class="color-picker-wrapper">
    <div
        class="picker-control"
        @click="showBaseColorPicker = !showBaseColorPicker"
    >
      <div
          class="color-display mr-2"
          :style="`background: ${backgroundColorPicker}`"
      ></div>
      <b-icon
          icon="droplet-fill"
          style="width: 20px; height: 20px; float: left; margin-top: 5px;"
      ></b-icon>
    </div>
    <div
        class="color-picker"
        v-if="showBaseColorPicker"
    >
      <chrome-picker v-model="tempColor" />
      <div class="d-flex justify-content-center">
        <b-button
            class="mb-3"
            variant="light"
            @click="cancelColor"
        >
          {{ $t('webmag.cancel') }}
        </b-button>
        <b-button
            class="ml-3 mb-3"
            variant="success"
            @click="saveColor"
        >
          {{ $t('webmag.select') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color';

export default {
  name: 'ColorPicker',
  components: {
    'chrome-picker': Chrome,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    showBaseColorPicker: false,
    tempColor: '',
  }),
  created() {
    this.tempColor = this.color;
  },
  computed: {
    backgroundColorPicker() {
      if (this.tempColor !== '') {
        if (Object.prototype.hasOwnProperty.call(this.tempColor, 'hex')) {
          return this.tempColor.hex;
        }
        return this.tempColor;
      }
      return 'url(data:image/gif;base64,R0lGODlhFAAUAPcAAAAAAJmZmZ2dnZ6enqKioqOjo6SkpKWlpaampqenp6ioqKmpqaqqqqurq/Hx8fLy8vT09PX19ff39/j4+Pn5+fr6+vv7+wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAUABQAAAihAP9FoPCvoMGDBy08+EdhQAIJCCMybCDAAYUEARBAlFiQQoMABQhKUJBxY0SPICEYHBnggEmDKAuoPMjS5cGYMxHW3IiT478JJA8M/CjTZ0GgLRekNGpwAsYABHIypcAgQMsITDtWJYBR6NSqMico9cqR6tKfY7GeBCuVwlipDNmefAtTrkSzB1RaIAoXodsABiZAEFB06gIBWC1mLVgBa0AAOw==) no-repeat 50%';
    },
  },
  methods: {
    cancelColor() {
      this.tempColor = this.color;
      this.showBaseColorPicker = false;
    },
    saveColor() {
      this.showBaseColorPicker = false;

      let newColor = '';
      if (this.tempColor !== '') {
        if (Object.prototype.hasOwnProperty.call(this.tempColor, 'hex')) {
          newColor = this.tempColor.hex;
        } else {
          newColor = this.tempColor;
        }
      } else {
        newColor = this.color;
      }
      this.$emit('update-color', newColor);
    },
  },
};
</script>

<style scoped lang="scss">
.picker-control {
  position: relative;
  display: inline-block;
  padding: 5px;
  border: 1px solid #c6c6c6;
  cursor: pointer;

  .color-display {
    float: left;
    width: 30px;
    height: 30px;
  }
}

.color-picker-wrapper {
  overflow: hidden;

  .color-picker {
    position: absolute;
    border: 1px solid #c6c6c6;
    border-radius: 4px;
    background-color: #fff;
    z-index: 99;

    .vc-chrome {
      box-shadow: none;
    }
  }
}
</style>
